import { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import constants from "../../helpers/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import _ from "../../../../src/@lodash";
import AuthService from "../../auth/services/authService";
import UserService from "../services/userService";
import { useNavigate } from "react-router-dom";
import SettingEmail from "./setting-email";
import SettingPhone from "./setting-phone";
import SettingPassword from "./setting-password";
import RecoveryEmail from "./recovery-email";
import RecoveryPhone from "./recovery-phone";
import * as yup from "yup";

var schema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z]*$/g, "Please enter between a - z, A - Z")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z]*$/g, "Please enter between a - z, A - Z")
    .required("Last name is required"),
});

var defaultValues = {
  firstName: "",
  lastName: "",
};

function Setting() {
  const navigate = useNavigate();
  const userDetail = AuthService.getUser();
  const settingTabSteps = constants?.settingTabSteps();
  const [tabValue, setTabValue] = useState(settingTabSteps.profile);
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  const getProfile = () => {
    setValue("firstName", userDetail?.firstName || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("lastName", userDetail?.lastName || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    getProfile();
  }, [setValue]);

  function onSubmit(profileVal) {
    setLoading(true);
    UserService.updateUser(profileVal)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            AuthService.setUser(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="tp-layout-page setting p-6 md:p-8 lg:p-12 xl:p-[50px]">
      <TabContext value={tabValue}>
        <TabList
          className="bg-gray-50 setting-page-tabs"
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="lab API tabs example"
        >
          <Tab label="Profile" value={settingTabSteps.profile} />
          <Tab
            label="Account Recovery"
            value={settingTabSteps.accountRecovery}
          />
        </TabList>
        <TabPanel value={settingTabSteps.profile} className="p-0">
          <div className="pt-6">
            <h2 className="font-medium text-primaryLime-500">
              Profile Information
            </h2>
            <div>
              Keep your profile up-to-date to enusure we have the correct
              details to contact you.
            </div>
            <div className="mt-6">
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="lg:flex item-center gap-6 w-full">
                  <div className="w-full lg:w-2/4 xl:w-2/6">
                    <label>First Name</label>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="w-full p-4"
                          autoFocus
                          type="text"
                          error={!!errors.firstName}
                          helperText={errors?.firstName?.message}
                          variant="outlined"
                          placeholder="Enter First Name"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-2/4 xl:w-2/6 mt-4 lg:mt-0">
                    <label>Last Name</label>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="w-full p-4"
                          autoFocus
                          type="text"
                          error={!!errors.lastName}
                          helperText={errors?.lastName?.message}
                          variant="outlined"
                          placeholder="Enter Last Name"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>

                <p>
                  <AppMessage
                    status={appStatus}
                    message={appMessage}
                    vertical="top"
                    horizontal="center"
                    isOpen={appOpen}
                    timeOut={100}
                  ></AppMessage>
                </p>

                <div className="flex item-center gap-6 w-full mt-6">
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out py-4"
                      aria-label="Sign in"
                      disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                      type="submit"
                      size="large"
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="secondary-btn btn-small bg-gray-200 hover:bg-gray-300 w-full rounded-xl transition duration-300 ease-in-out py-4"
                      aria-label="Cancel"
                      size="large"
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="lg:flex items-center w-full gap-6 mt-8">
              <div className="w-full lg:w-2/4 xl:w-2/6">
                <SettingEmail />
              </div>
              <div className="w-full lg:w-2/4 xl:w-2/6">
                <SettingPhone />
              </div>
            </div>
            <div className="lg:flex items-center w-full gap-6 mt-8">
              <div className="w-full lg:w-2/4 xl:w-2/6">
                <SettingPassword />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={settingTabSteps.accountRecovery}>
          <div className="pt-6">
            <h2 className="font-medium text-primaryLime-500">
            Account Recovery
            </h2>
            <div>
            Please choose how you would like to set up your account recovery.
              You can use an email address or a phone number.
            </div>
            <div className={(userDetail?.recoveryPhoneNumber && !userDetail?.recoveryEmail || !userDetail?.recoveryPhoneNumber && userDetail?.recoveryEmail )?"lg:flex flex-col gap-6 mt-6 items-start": "lg:flex gap-6 mt-6 items-center"}>
              <div className="w-full lg:w-2/4 xl:w-2/6">
                <RecoveryEmail/>
              </div>
              <div className="w-full lg:w-2/4 xl:w-2/6 mt-6 lg:mt-0">
                <RecoveryPhone/>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Setting;
