import React, { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import constants from "../helpers/constants";
import logo from "../assets/images/logo.png";

function Sidebar({handleOnInAciveClick}) {
  const navigate = useNavigate();
  const sidebarMenu = constants?.sidebarMenu();
  const [activeSidebarMenu, setActiveSidebarMenu] = useState(
    sidebarMenu.dashboard
  );

  function signOut() {
    localStorage.clear();
    navigate("/");
    //return <Navigate to="/" />;
    window.location.reload();
  }

  return (
    <div className="min-w-[250px] bg-black h-full">
      <div className="h-[60px] lg:h-[80px] flex items-center justify-between md:justify-center border-b border-gray-800 px-4 md:px-0">
        <img className="w-1/2 lg:w-3/5" src={logo} alt="auth image" />
        <a className="block md:hidden" onClick={()=> handleOnInAciveClick()}><i class="fi fi-rr-cross text-white"></i></a>
      </div>
      <div className="pt-6 md:pt-8 lg:pt-12 relative taxpert-sidebar">
        <ul className="text-white list-none">
          <li>
            <Link
              to="/home"
              onClick={() => setActiveSidebarMenu(sidebarMenu.dashboard)}
              className={
                activeSidebarMenu === sidebarMenu.dashboard
                  ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                  : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
              }
            >
              <i class="fi fi-rr-home mr-3 top-[2px] relative"></i>Home
            </Link>
          </li>
          <li>
            <Link
              to="/setting"
              onClick={() => setActiveSidebarMenu(sidebarMenu.settings)}
              className={
                activeSidebarMenu === sidebarMenu.settings
                  ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                  : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
              }
            >
              <i class="fi fi-rr-settings mr-3 top-[2px] relative"></i>Settings
            </Link>
          </li>
        </ul>
        <div className="absolute w-full bottom-4 md:bottom-8 lg:bottom-14">
          <Link
            className={
              activeSidebarMenu === sidebarMenu.signOut
                ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center text-white transition duration-300 ease-in-out sidebar-list-item active"
                : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center text-white transition duration-300 ease-in-out sidebar-list-item"
            }
            onClick={() => {
              setActiveSidebarMenu(sidebarMenu.signOut);
              signOut();
            }}
          >
            <i class="fi fi-rr-sign-out-alt mr-3 top-[2px] relative"></i>Sign
            Out
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
