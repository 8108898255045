import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from "./layout";
import AuthService from "../auth/services/authService"; 

const PrivateRoute = ({ isAuthenticated }) => {
  if (!AuthService.isAuthenticated()) {
  //if (!isAuthenticated) {
    // Redirect them to the login page if not authenticated
    return <Navigate to="/" />;
  }

  // If authenticated, render the child routes
  return (
    <Layout />
  );
};

export default PrivateRoute;