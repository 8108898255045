import { useEffect, useState } from "react";
import constants from "../../helpers/constants";
import successfullyMark from "../../assets/images/success-mark.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import _ from "../../../../src/@lodash";
import AuthService from "../../auth/services/authService";
import UserService from "../services/userService";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from "yup";

var passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

var defaultPasswordValues = {
  password: "",
};

var newSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .required("Please enter your password.")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

var defaultNewValues = {
  newPassword: "",
  passwordConfirm: "",
};

var resetSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  newPassword: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  confirmPassword: yup
    .string()
    .required("Please enter your password.")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

var defaultResetValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function SettingPassword() {
  const userDetail = AuthService.getUser();
  const settingSteps = constants?.signUpSteps();
  const [passwordSteps, setPasswordSteps] = useState(settingSteps.signUp);
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  var defaultValues = null;
  var schema = null;

  if (isResetPassword) {
    defaultValues = showModal ? defaultResetValues : defaultPasswordValues;
    schema = showModal ? resetSchema : passwordSchema;
  } else {
    defaultValues = showModal ? defaultNewValues : defaultPasswordValues;
    schema = showModal ? newSchema : passwordSchema;
  }

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const getProfile = () => {
    setValue("password", userDetail?.password || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    getProfile();
  }, [setValue]);

  function onSubmit({ newPassword }) {
    setLoading(true);
    UserService.updatePassword(newPassword)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            userDetail.password = newPassword;
            AuthService.setUser(userDetail);
            getProfile();
            setPasswordSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onResetSubmit({ currentPassword, newPassword }) {
    setLoading(true);
    UserService.resetPassword(currentPassword, newPassword)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            userDetail.password = currentPassword;
            AuthService.setUser(userDetail);
            getProfile();
            setPasswordSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div>
      <div>
        {userDetail?.password ? (
          <form
            name="settingForm"
            noValidate
            className="flex flex-col justify-center w-full"
          >
            <div>
              <label className="mb-2">Password</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    placeholder="Enter Password"
                    required
                    fullWidth
                  />
                )}
              />
              <p className="mt-3 leading-7">
                <Link
                  className="text-primaryLime-500"
                  onClick={() => {
                    setPasswordSteps(settingSteps.signUp);
                    setIsResetPassword(true);
                    setShowModal(true);
                  }}
                >
                  Reset Password
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <p className="mt-6 leading-7">
            <div>Password</div>
            <Link
              className="text-primaryLime-500"
              onClick={() => {
                setPasswordSteps(settingSteps.signUp);
                setShowModal(true);
              }}
            >
              Set Password
            </Link>
          </p>
        )}
      </div>{" "}
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(passwordSteps === settingSteps.signUp &&
            (isResetPassword ? (
              <form
                name="resetForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onResetSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
                >
                  Reset Your Password
                </DialogTitle>
                <DialogContent className='dialog-content'>
                  <DialogContentText id="alert-dialog-description">
                    <div>
                      <p className="text-center text-black">
                        Set Your Password for{" "}
                        {userDetail?.emailAddresses?.emailAddress1}
                      </p>
                      <div className="mt-8">
                        <div>
                          <label className="mb-2">Current Password</label>
                          <Controller
                            name="currentPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type="password"
                                error={!!errors.currentPassword}
                                helperText={errors?.currentPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="my-6">
                          <label className="mb-2">New Password</label>
                          <Controller
                            name="newPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type="password"
                                error={!!errors.newPassword}
                                helperText={errors?.newPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div>
                          <label className="mb-2">Confirm Password</label>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type="password"
                                error={!!errors.confirmPassword}
                                helperText={errors?.confirmPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <p>
                          <AppMessage
                            status={appStatus}
                            message={appMessage}
                            vertical="top"
                            horizontal="center"
                            isOpen={appOpen}
                            timeOut={100}
                          ></AppMessage>
                        </p>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                      type="submit"
                      size="large"
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            ) : (
              <form
                name="newForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
                >
                  Set Your Password
                </DialogTitle>
                <DialogContent className='dialog-content'>
                  <DialogContentText id="alert-dialog-description">
                    <div>
                      <p className="text-center text-black">
                        Set Your New Password for{" "}
                        {userDetail?.emailAddresses?.emailAddress1}
                      </p>
                      <div className="mt-8">
                        <label className="mb-2">New Password</label>
                        <Controller
                          name="newPassword"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              type="password"
                              error={!!errors.newPassword}
                              helperText={errors?.newPassword?.message}
                              variant="outlined"
                              placeholder="Enter Password"
                              required
                              fullWidth
                            />
                          )}
                        />
                        <label className="mb-2">Confirm Password</label>
                        <Controller
                          name="passwordConfirm"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              type="password"
                              error={!!errors.passwordConfirm}
                              helperText={errors?.passwordConfirm?.message}
                              variant="outlined"
                              placeholder="Enter Password"
                              required
                              fullWidth
                            />
                          )}
                        />
                        <p>
                          <AppMessage
                            status={appStatus}
                            message={appMessage}
                            vertical="top"
                            horizontal="center"
                            isOpen={appOpen}
                            timeOut={100}
                          ></AppMessage>
                        </p>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                      type="submit"
                      size="large"
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            ))) ||
            (passwordSteps === settingSteps.signUpSuccess && (
              <div>
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
                >
                  <span>
                <img
                  className="mx-auto block mb-4 w-[50px]"
                  src={successfullyMark}
                  alt="successfully"
                />
              </span>
                  Password Added Successfully
                </DialogTitle>
                <DialogContent className='dialog-content'>
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Your password has been updated. You can now use your new
                      password to sign in.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      onClick={() => setShowModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
    </div>
  );
}
export default SettingPassword;
