import { useEffect, useState } from "react";
import authImage from "../../assets/images/tp-auth.png";
import logo from "../../assets/images/logo.png";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "../../../../src/@lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthService from "../services/authService";
import constants from "../../helpers/constants";
import OtpInput from "react-otp-input";
import { useNavigate, Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "yup-phone";

var emailSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

const phoneSchema = yup.object().shape({
  phone: yup.string().required("Phone number is not valid"),
});

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultEmailValues = {
  email: "",
};

const defaultPhoneValues = {
  phone: "",
};

const defaultPasswordValues = {
  password: "",
  passwordConfirm: "",
};

function ForgotPassword() {
  const forgotPasswordSteps = constants?.forgotPasswordSteps();
  const [forgotPasswordStep, setForgotPasswordStep] = useState(
    forgotPasswordSteps.forgotPassword
  );
  const navigate = useNavigate();

  const [isEmail, setIsEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  var defaultValues =
    forgotPasswordStep == forgotPasswordSteps.newPassword
      ? defaultPasswordValues
      : isEmail
      ? defaultEmailValues
      : defaultPhoneValues;
  var schema =
    forgotPasswordStep == forgotPasswordSteps.newPassword
      ? passwordSchema
      : isEmail
      ? emailSchema
      : phoneSchema;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (isEmail) {
      setValue("email", "", { shouldDirty: true, shouldValidate: true });
    } else {
      setValue("phone", "", { shouldDirty: true, shouldValidate: true });
    }
  }, [setValue]);

  function onEmailSubmit({ email }) {
    setLoading(true);
    setEmail(email);
    AuthService.signInWithOTP(email)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setForgotPasswordStep(forgotPasswordSteps.forgotPasswordOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onForgotPasswordWithPhone({ phone }) {
    setLoading(true);
    setPhone(phone);
    AuthService.forgotPasswordWithPhone(phone)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data != "") {
            setOtp(response?.data);
            setForgotPasswordStep(forgotPasswordSteps.forgotPasswordOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onVerifyForgotPassword() {
    setLoading(true);
    var data = isEmail ? email : phone;
    AuthService.verifyForgotPassword(data, isEmail, otp)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setForgotPasswordStep(forgotPasswordSteps.newPassword);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onForgotNewPassword({ password }) {
    setLoading(true);
    var data = isEmail ? email : phone;
    AuthService.onForgotNewPassword(data, isEmail, password)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setShowModal(true);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="bg-black">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4"
                  src={logo}
                  alt="auth image"
                />
                <img className="mx-auto" src={authImage} alt="auth image" />
                <h1 className="auth-heading font-semibold mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="max-w-[600px] w-full relative">
                  <img
                    className="mx-auto hidden xl:block"
                    src={logo}
                    alt="auth image"
                  />
                  <div className="bg-white w-full rounded-xl mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                    {(forgotPasswordStep === forgotPasswordSteps.forgotPassword && (
                      <div>
                        <button
                          className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                          onClick={() => navigate("/")}
                        >
                          <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                        </button>
                        <h1 className="text-center">
                          Forgot Your Password?{" "}
                        </h1>
                        <p className="leading-7 text-center px-6 mt-5">
                          Enter your registered email address or phone number, and
                          we'll send you OTP to reset your password.
                        </p>
                        <div className="mt-[50px]">
                          <div className="flex items-center">
                            <label>Email Address or Phone Number</label>
                            <div>
                              <Controller
                                name="isEmail"
                                control={control}
                                render={({ field }) => (
                                  <FormControl
                                    component="fieldset"
                                    className="FuseSettings-formControl flex flex-row"
                                  >
                                    <div className="flex items-center ml-6">
                                      <Switch
                                        onChange={(ev) => {
                                          setIsEmail(ev.target.checked);
                                          setOtp("");
                                        }}
                                        aria-label={"abc"}
                                      />
                                      <FormLabel
                                        component="legend"
                                        className="text-14"
                                      >
                                        Is Email
                                      </FormLabel>
                                    </div>
                                  </FormControl>
                                )}
                              />
                            </div>
                          </div>

                          {(isEmail && (
                            <form
                              name="forgotForm"
                              noValidate
                              className="flex flex-col justify-center w-full mt-6"
                              onSubmit={handleSubmit(onEmailSubmit)}
                            >
                              <label>Email Address</label>
                              <Controller
                                name="email"
                                control={control}
                                id="phone"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="w-full p-4 rounded-xl"
                                    autoFocus
                                    type="email"
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    value={null}
                                    variant="outlined"
                                    placeholder="Enter Email Address"
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                              <p>
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </p>
                              <div className="mt-12">
                                <Button
                                  name="sendEmail"
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label="Send OTP"
                                  disabled={
                                    _.isEmpty(dirtyFields) || !isValid || loading
                                  }
                                  type="submit"
                                  size="large"
                                >
                                  {(loading && (
                                    <PulseLoader
                                      color="#000000"
                                      loading={loading}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  )) ||
                                    "Send OTP"}
                                </Button>
                              </div>
                            </form>
                          )) || (
                            <form
                              name="forgotPhoneForm"
                              noValidate
                              className="flex flex-col justify-center w-full mt-6"
                              onSubmit={handleSubmit(onForgotPasswordWithPhone)}
                            >
                              <label>Phone</label>
                              <Controller
                                name="phone"
                                control={control}
                                id="phone"
                                render={({ field }) => (
                                  <PhoneInput
                                    {...field}
                                    country={"in"}
                                    value={null}
                                    className="phone-number-input w-full transition duration-300 ease-in-out"
                                    required
                                  />
                                )}
                              />
                              <p>
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </p>
                              <div className="mt-12">
                                <Button
                                  name="sendPhone"
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label=" Send OTP"
                                  disabled={
                                    _.isEmpty(dirtyFields) || !isValid || loading
                                  }
                                  type="submit"
                                  size="large"
                                >
                                  {(loading && (
                                    <PulseLoader
                                      color="#000000"
                                      loading={loading}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  )) ||
                                    "Send OTP"}
                                </Button>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    )) ||
                      (forgotPasswordStep ===
                        forgotPasswordSteps.forgotPasswordOTP && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                            onClick={() =>
                              setForgotPasswordStep(
                                forgotPasswordSteps.forgotPassword
                              )
                            }
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">
                            Forgot Your Password?
                          </h1>
                          <p className="leading-7 text-center mt-4">
                            Enter the OTP sent On{" "}
                            <span className="font-semibold">
                              {isEmail ? email : phone}
                            </span>
                          </p>

                          <div className="mt-[50px]">
                            <label className="mb-2">Enter OTP</label>
                            <div className="otp-input">
                              <OtpInput
                                className="w-full p-4 rounded-xl"
                                inputStyle="inputStyle"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                placeholder="------"
                                required
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            <p className="text-right mt-5 text-sm">
                              Didn't get OTP code?{" "}
                              <a
                                href="/"
                                className="text-primaryLime-500 underline"
                              >
                                Resend OTP
                              </a>
                            </p>
                          </div>
                          <p>
                            <AppMessage
                              status={appStatus}
                              message={appMessage}
                              vertical="top"
                              horizontal="center"
                              isOpen={appOpen}
                              timeOut={100}
                            ></AppMessage>
                          </p>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out mt-5"
                            aria-label="Sign in"
                            type="submit"
                            onClick={() => onVerifyForgotPassword()}
                            size="large"
                            disabled={loading}
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Set Password"}
                          </Button>
                        </div>
                      )) ||
                      (forgotPasswordStep === forgotPasswordSteps.newPassword && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                            onClick={() =>
                              setForgotPasswordStep(
                                forgotPasswordSteps.forgotPasswordOTP
                              )
                            }
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">
                            Forgot Your Password?
                          </h1>
                          <p className="leading-7 text-center mt-4">
                            Set Your New Password for{" "}
                            <span className="font-semibold">
                              {isEmail ? email : phone}
                            </span>
                          </p>
                          <div className="mt-[50px]">
                            <form
                              name="loginForm"
                              noValidate
                              className="flex flex-col justify-center w-full"
                              onSubmit={handleSubmit(onForgotNewPassword)}
                            >
                              <div className="mb-8">
                                <label className="mb-2">Enter Password</label>
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      type="password"
                                      error={!!errors.password}
                                      helperText={errors?.password?.message}
                                      variant="outlined"
                                      placeholder="Enter Password"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-8">
                                <label className="mb-2">Conform Password</label>
                                <Controller
                                  name="passwordConfirm"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      type="password"
                                      error={!!errors.passwordConfirm}
                                      helperText={errors?.passwordConfirm?.message}
                                      variant="outlined"
                                      placeholder="Enter Password"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <p>
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </p>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                type="submit"
                                size="large"
                                disabled={
                                  _.isEmpty(dirtyFields) || !isValid || loading
                                }
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Sign in"}
                              </Button>
                            </form>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Dialog
                  open={showModal}
                  onClose={() => {
                    setShowModal(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3">
                    {"Use Google's location service?"}
                  </DialogTitle>
                  <DialogContent className='dialog-content'>
                    <DialogContentText id="alert-dialog-description">
                      <p className="text-black">
                        Let Google help apps determine location. This means
                        sending anonymous location data to Google, even when no
                        apps are running.
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className='dialog-actions'>
                    <button
                      className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      onClick={() => {
                        setShowModal(false);
                        navigate("/");
                      }}
                    >
                      OK
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
