import { useEffect, useState } from "react";
import constants from "../../helpers/constants";
import TextField from "@mui/material/TextField";
import successfullyMark from "../../assets/images/success-mark.png";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import _ from "../../../../src/@lodash";
import AuthService from "../../auth/services/authService";
import UserService from "../services/userService";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";

var schema = yup.object().shape({
  phone: yup.string().required("Phone number is not valid"),
});

var defaultValues = {
  phone: "",
};

function RecoveryPhone() {
  const userDetail = AuthService.getUser();
  const settingSteps = constants?.signUpSteps();
  const [phoneSteps, setPhoneSteps] = useState(settingSteps.signUp);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState(userDetail?.recoveryPhoneNumber);
  const [otp, setOtp] = useState("");
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const getProfile = () => {
    setValue("phone", userDetail?.recoveryPhoneNumber || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    getProfile();
  }, [setValue]);

  function onSubmit({ phone }) {
    setLoading(true);
    setPhone(phone);
    UserService.updateRecoveryPhone(phone)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setPhoneSteps(settingSteps.signUpWithOTP);
            setOtp(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onOtpSubmit() {
    setLoading(true);
    UserService.verifyRecoveryPhone(phone, otp)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            userDetail.recoveryPhoneNumber = phone;
            AuthService.setUser(userDetail);
            setPhoneSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    UserService.reSendPhoneOTP(phone)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setOtp(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 5000);
  }
  return (
    <div>
      <div>
        {userDetail?.recoveryPhoneNumber ? (
          <form
            name="settingForm"
            noValidate
            className="flex flex-col justify-center w-full"
          >
            <div>
              <label className="mb-2">Phone Number</label>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={"in"}
                    value={phone}
                    className="phone-number-input w-full transition duration-300 ease-in-out"
                    required
                  />
                )}
              />
              <p className="mt-3 leading-7">
                <Link
                  className="text-primaryLime-500"
                  onClick={() => {
                    setPhoneSteps(settingSteps.signUp);
                    setShowModal(true);
                  }}
                >
                  Change Recovery Phone Number
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <div className="leading-7">
            <Button
              variant="contained"
              color="secondary"
              className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
              aria-label="Sign in"
              type="submit"
              size="large"
              onClick={() => {
                setPhoneSteps(settingSteps.signUp);
                setShowModal(true);
              }}
            >
              Add Recovery Phone Number
            </Button>
          </div>
        )}
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(phoneSteps === settingSteps.signUp && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
              >
                {userDetail?.recoveryPhoneNumber
                  ? "Change Phone Number"
                  : "Add Phone Number"}
              </DialogTitle>
              <DialogContent className="dialog-content h-full overflow-visible">
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Enter your new Phone Number below. <br /> OTP will be send
                      this address to confirm the change.
                    </p>
                    <div className="mt-8">
                      <label className="mb-2">Phone Number</label>
                      <Controller
                        name="phone"
                        control={control}
                        id="phone"
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={"in"}
                            value={null}
                            className="phone-number-input w-full transition duration-300 ease-in-out"
                            required
                          />
                        )}
                      />
                      <p>
                        <AppMessage
                          status={appStatus}
                          message={appMessage}
                          vertical="top"
                          horizontal="center"
                          isOpen={appOpen}
                          timeOut={100}
                        ></AppMessage>
                      </p>
                    </div>
                  </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                    size="large"
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )) ||
            (phoneSteps === settingSteps.signUpWithOTP && (
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onOtpSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
                >
                  Confirm OTP
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Enter the OTP sent on {phone}
                    </p>
                    <div className="mt-8">
                      <div>
                        <label className="mb-2">Enter OTP</label>
                        <div className="otp-input">
                          <OtpInput
                            className="w-full p-4"
                            inputStyle="inputStyle"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            placeholder="------"
                            required
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <p className="text-right mt-5 text-sm">
                          Didn't get OTP code?{" "}
                          <a
                            disabled={loading}
                            href="javascript:void(0);"
                            onClick={() => onReSendOTP()}
                            className="text-primaryLime-500"
                          >
                            Resend OTP
                          </a>
                        </p>
                        <p>
                          <AppMessage
                            status={appStatus}
                            message={appMessage}
                            vertical="top"
                            horizontal="center"
                            isOpen={appOpen}
                            timeOut={100}
                          ></AppMessage>
                        </p>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className="px-4 pb-4 w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={(otp.length !== 6 && true) || false}
                      type="submit"
                      size="large"
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            )) ||
            (phoneSteps === settingSteps.signUpSuccess && (
              <div>
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3"
                ><span>
                <img
                  className="mx-auto block mb-4 w-[50px]"
                  src={successfullyMark}
                  alt="successfully"
                />
              </span>
                  Recovery Phone Number Set!
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Your Recovery Phone Number has been successfully updated.
                      You can now use {phone} to recover your account if needed.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      onClick={() => setShowModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
    </div>
  );
}

export default RecoveryPhone;
