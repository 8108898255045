import * as CryptoJS from "crypto-js";
class Constants {
  signInSteps = () => {
    return {
      signIn: 0,
      signInNext: 1,
      signInWithOTP: 2,
      signInWithPassword: 3,
    };
  };

  forgotPasswordSteps = () => {
    return {
      forgotPassword: 0,
      forgotPasswordOTP: 1,
      newPassword: 2,
    };
  };

  signUpSteps = () => {
    return {
      signUp: 0,
      signUpWithOTP: 1,
      signUpSuccess: 2,
    };
  };

  messageStatusCodes = () => {
    return {
      success: 200,
      badRequest: 400,
      internalServerError: 500,
    };
  };

  settingTabSteps = () => {
    return {
      profile: 0,
      accountRecovery: 1,
    };
  };

  sidebarMenu = () => {
    return {
      dashboard: 0,
      settings: 1,
      signOut: 2,
    };
  };

  encrypt = (plainText) => {
    const cipherText = CryptoJS.AES.encrypt(plainText, "0nMixNET").toString();
    return cipherText;
  };

  decrypt = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, "0nMixNET");
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    return plainText;
  };
}
const instance = new Constants();
export default instance;
