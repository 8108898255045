function Header({usernName, className, handleOnAciveClick }) {
    return(
        <div className={className+"w-full"}>
                <div className='h-[60px] lg:h-[80px] border-b flex items-center justify-between px-6 md:px-8 lg:px-12 xl:px-[50px]'>
                    <div className="flex items-center">
                        <a className="block md:hidden" onClick={()=> handleOnAciveClick()}><i class="fi fi-rr-menu-burger text-lg mr-4"></i></a>
                        <p className='text-base font-semibold flex items-center'><span className="hidden md:block mr-1">Welcome </span> {usernName} </p>
                    </div>
                    <div>
                        <div class="relative inline-block text-left">
                            <div>
                                <button type="button" class="flex items-center w-full justify-center rounde hover:bg-gray-50 font-medium" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span className='bg-black w-10 h-10 rounded-full md:mr-2 flex items-center justify-center'><i class="fi fi-rr-user text-white text-lg mt-1.5"></i></span> 
                                    <span className="hidden md:block">{usernName}</span>
                                </button>
                            </div>
                            <div class="hidden absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="py-1" role="none">
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                                    <form method="POST" action="#" role="none">
                                        <button type="submit" class="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    );
}
export default Header;