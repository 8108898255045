import { useEffect, useState } from "react";
import authImage from "../../assets/images/tp-auth.png";
import logo from "../../assets/images/logo.png";
import * as yup from "yup";
import _ from "../../../../src/@lodash";
import AuthService from "../services/authService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import OtpInput from "react-otp-input";
import constants from "../../helpers/constants";
import { useNavigate, Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";

var schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

var defaultValues = {
  email: "",
};

const schemaPwd = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password is too short - must be at least 4 chars."),
  terms: yup
    .bool()
    .oneOf([true], "must be check Terms & Conditions")
    .required("Please accept."),
});

const defaultPwdValues = {
  password: "",
  terms: false,
};

function SignIn() {
  const signInSteps = constants?.signInSteps();
  const [signInStep, setSignInStep] = useState(signInSteps.signIn);

  const signUpSteps = constants?.signUpSteps();
  const [signUpStep, setSignUpStep] = useState(signUpSteps.signUp);

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  const navigate = useNavigate();

  defaultValues =
    signInStep === signInSteps.signInWithPassword
      ? defaultPwdValues
      : defaultValues;
  schema = signInStep === signInSteps.signInWithPassword ? schemaPwd : schema;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (signInStep === signInSteps.signInWithPassword) {
      setValue("password", "", { shouldDirty: true, shouldValidate: true });
      setValue("terms", "", { shouldDirty: true, shouldValidate: true });
    } else setValue("email", "", { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  function onSubmit({ email }) {
    setLoading(true);
    setEmail(email);
    AuthService.doesEmailAddressExists(email)
      .then((response) => {
        showAppMessage(response);
        setIsSignIn(response.data);
        if (response?.statusCode === 200) {
          if (response.data) {
            setSignInStep(signInSteps.signInNext);
          } else {
            setSignUpStep(signUpSteps.signUpWithOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
        // _errors.forEach((error) => {
        //     setError(error.type, {
        //         type: 'manual',
        //         message: error.message,
        //     });
        // });
      });
  }

  function signInWithOTP() {
    setLoading(true);
    AuthService.signInWithOTP(email)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) setSignInStep(signInSteps.signInWithOTP);
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    AuthService.reSendOTP(email, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          //if (response?.data)
          //setSignInStep(signInSteps.signInWithOTP);
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  async function onSignIn() {
    setLoading(true);
    if (signInStep === signInSteps.signInWithOTP) {
      await AuthService.signIn(email, true, otp)
        .then((response) => {
          showAppMessage(response);
          setLoading(false);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/home");
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSignInWithPassword({ password }) {
    setLoading(true);
    if (signInStep === signInSteps.signInWithPassword) {
      AuthService.signIn(email, false, password)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/home");
            }
          }
          setLoading(false);
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function onSignUpReSendOTP() {
    setLoading(true);
    AuthService.reSendOTP(email, false)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          //if (response?.data)
          //setSignInStep(signInSteps.signInWithOTP);          
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onVerifySignUp() {
    setLoading(true);
    if (signUpStep === signUpSteps.signUpWithOTP) {
      AuthService.verifySignUp(email, otp)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              setSignUpStep(signUpSteps.signUpSuccess);
            }
          }
          setLoading(false);
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSignUp() {
    setLoading(true);
    if (signUpStep === signUpSteps.signUpSuccess) {
      AuthService.signUpUser(email, otp)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/home");
            }
          }
          setLoading(false);
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  return (
    <div className="bg-black">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4"
                  src={logo}
                  alt="auth image"
                />
                <img className="mx-auto" src={authImage} alt="auth image" />
                <h1 className="auth-heading font-semibold mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="sm:w-[600px] w-full relative">
                    <img
                        className="mx-auto hidden xl:block"
                        src={logo}
                        alt="auth image"
                      />
                    <div className="bg-white w-full rounded-xl mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                      {(!isSignIn && (signUpStep === signUpSteps.signUpWithOTP && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                            onClick={() => {setIsSignIn(true); setSignInStep(signInSteps.signIn)}}
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">
                            Welcome Christopher
                          </h1>
                          <p className="leading-7 text-center mt-4">
                            Enter the OTP sent On{" "}
                            <span className="font-semibold">
                              {email}
                            </span>
                          </p>
                          <div>
                            <label className="mb-2">Enter OTP</label>
                            <div className="otp-input">
                              <OtpInput
                                className="w-full p-4"
                                inputStyle="inputStyle"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                placeholder="------"
                                required
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            <p className="text-right mt-5 text-sm">
                              Didn't get OTP code?{" "}
                              <a
                                disabled={loading}
                                href="javascript:void(0);"
                                onClick={() => onSignUpReSendOTP()}
                                className="text-primaryLime-500"
                              >
                                Resend OTP
                              </a>
                            </p>
                          </div>
                          <div className="flex items-start my-5">
                            <div className="flex items-center h-5">
                              <Controller
                                name="remember"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                    required
                                  />
                                )}
                              />
                            </div>
                            <label className="ms-2 text-sm">
                              I agree to the{" "}
                              <Link
                                className="underline text-primaryLime-500"
                                to="/terms"
                              >
                                Terms & Conditions
                              </Link>
                            </label>
                          </div>
                          <p>
                              <AppMessage
                                status={appStatus}
                                message={appMessage}
                                vertical="top"
                                horizontal="center"
                                isOpen={appOpen}
                                timeOut={100}
                              ></AppMessage>
                            </p>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                            aria-label="Sign in"
                            type="submit"
                            onClick={() => onVerifySignUp()}
                            size="large"
                            disabled={loading}
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Sign in"}
                          </Button>
                        </div>
                        )) ||
                        (signUpStep === signUpSteps.signUpSuccess && (
                          <div>
                            <h1 className="text-center">
                              Wollah Christopher!
                            </h1>
                            <p className="mb-[50px] leading-7 text-center mt-4">
                              You're all set! Proceed to your dashboard
                            </p>
                            <p>
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </p>
                            <Button
                              variant="contained"
                              color="secondary"
                              className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                              aria-label="Sign in"
                              type="submit"
                              onClick={() => onSignUp()}
                              size="large"
                              disabled={loading}
                            >
                              {(loading && (
                                <PulseLoader
                                  color="#000000"
                                  loading={loading}
                                  size={10}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              )) ||
                                "Explore Dashboard"}
                            </Button>
                          </div>
                        ))) ||
                        (isSignIn &&
                          ((signInStep === signInSteps.signIn && (
                            <div>
                              <h1 className="text-center mb-6 sm:mb-8 md:mb-12 lg:mb-[50px]">Welcome</h1>
                              <form
                                name="loginForm"
                                noValidate
                                className="flex flex-col justify-center w-full"
                                onSubmit={handleSubmit(onSubmit)}
                              >
                                <label className="mb-2">Email Address</label>
                                <Controller
                                  name="email"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="w-full p-4"
                                      autoFocus
                                      type="email"
                                      error={!!errors.email}
                                      helperText={errors?.email?.message}
                                      variant="outlined"
                                      placeholder="Enter Email address"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                                <p className="my-6 leading-7">
                                  Note: We'll check if you already have an account.
                                  If not, we'll create one for you. Then, we'll send
                                  an OTP to your email address for verification.
                                </p>
                                <p>
                                  <AppMessage
                                    status={appStatus}
                                    message={appMessage}
                                    vertical="top"
                                    horizontal="center"
                                    isOpen={appOpen}
                                    timeOut={100}
                                  ></AppMessage>
                                </p>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label="Sign in"
                                  disabled={
                                    _.isEmpty(dirtyFields) || !isValid || loading
                                  }
                                  type="submit"
                                  size="large"
                                >
                                  {(loading && (
                                    <PulseLoader
                                      color="#000000"
                                      loading={loading}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  )) ||
                                    "Next"}
                                </Button>
                              </form>
                            </div>
                          )) ||
                            (signInStep === signInSteps.signInNext && (
                              <div>
                                <button
                                  className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                                  onClick={() => setSignInStep(signInSteps.signIn)}
                                >
                                  <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                                </button>
                                <div>
                                  <h1 className="text-center">
                                    Choose Your Sign in Method
                                  </h1>
                                  <p className="leading-7 text-center font-semibold mt-4">
                                    Select your Sign in method for {email}
                                  </p>
                                  <div className="md:flex gap-8 mt-[50px] w-full">
                                    <div className="md:w-2/4">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                        aria-label="Sign in with OTP"
                                        type="submit"
                                        size="large"
                                        disabled={loading}
                                        onClick={() => signInWithOTP()}
                                      >
                                        {(loading && (
                                          <PulseLoader
                                            color="#000000"
                                            loading={loading}
                                            size={10}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                          />
                                        )) ||
                                          "Sign in with OTP"}
                                      </Button>
                                    </div>
                                    <div className="md:w-2/4 mt-4 md:mt-0">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                        aria-label="Sign in with Password"
                                        type="submit"
                                        size="large"
                                        onClick={() =>
                                          setSignInStep(
                                            signInSteps.signInWithPassword
                                          )
                                        }
                                      >
                                        Sign in with Password
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )) ||
                            (signInStep === signInSteps.signInWithOTP && (
                              <div>
                                <button
                                  className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                                  onClick={() =>
                                    setSignInStep(signInSteps.signInNext)
                                  }
                                >
                                  <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                                </button>
                                <h1 className="text-center">
                                  Welcome Christopher
                                </h1>
                                <p className="leading-7 text-center  mt-4">
                                  Enter the OTP sent On{" "}
                                  <span className="font-semibold">{email}</span>
                                </p>
                                <div className="mt-[50px]">
                                  <label className="mb-2">Enter OTP</label>
                                  <div className="otp-input">
                                    <OtpInput
                                      className="w-full p-4"
                                      inputStyle="inputStyle"
                                      value={otp}
                                      onChange={setOtp}
                                      numInputs={6}
                                      placeholder="------"
                                      required
                                      renderInput={(props) => <input {...props} />}
                                    />
                                  </div>
                                  <p className="text-right mt-5 text-sm">
                                    Didn't get OTP code?{" "}
                                    <a
                                      disabled={loading}
                                      href="javascript:void(0);"
                                      onClick={() => onReSendOTP()}
                                      className="text-primaryLime-500"
                                    >
                                      Resend OTP
                                    </a>
                                  </p>
                                </div>
                                <div className="flex items-start my-5">
                                  <div className="flex items-center h-5">
                                    <Controller
                                      name="remember"
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          onChange={(e) =>
                                            setTerms(e.target.checked)
                                          }
                                          className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                          required
                                        />
                                      )}
                                    />
                                  </div>
                                  <label className="ms-2 text-sm">
                                    I agree to the{" "}
                                    <Link
                                      className="underline text-primaryLime-500"
                                      to="/terms"
                                    >
                                      Terms & Conditions
                                    </Link>
                                  </label>
                                </div>
                                <p>
                                  <AppMessage
                                    status={appStatus}
                                    message={appMessage}
                                    vertical="top"
                                    horizontal="center"
                                    isOpen={appOpen}
                                    timeOut={100}
                                  ></AppMessage>
                                </p>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label="Sign in"
                                  type="submit"
                                  onClick={() => onSignIn()}
                                  size="large"
                                  disabled={
                                    !terms ||
                                    (otp.length !== 6 && true) ||
                                    false ||
                                    loading
                                  }
                                >
                                  {(loading && (
                                    <PulseLoader
                                      color="#000000"
                                      loading={loading}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  )) ||
                                    "Sign in"}
                                </Button>
                              </div>
                            )) ||
                            (signInStep === signInSteps.signInWithPassword && (
                              <div>
                                <button
                                  className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6"
                                  onClick={() =>
                                    setSignInStep(signInSteps.signInNext)
                                  }
                                >
                                  <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                                </button>
                                <h1 className="text-center">
                                  Welcome Christopher
                                </h1>
                                <p className="leading-7 text-center">
                                  Sign in as:{" "}
                                  <span className="font-semibold"> {email} </span>
                                </p>
                                <div className="mt-[50px]">
                                  <form
                                    name="loginForm"
                                    noValidate
                                    className="flex flex-col justify-center w-full"
                                    onSubmit={handleSubmit(onSignInWithPassword)}
                                  >
                                    <div>
                                      <label className="mb-2">Password</label>
                                      <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            className="mb-24"
                                            type="password"
                                            error={!!errors.password}
                                            helperText={errors?.password?.message}
                                            variant="outlined"
                                            placeholder="Enter Password"
                                            required
                                            fullWidth
                                          />
                                        )}
                                      />
                                      <p className="text-right mt-5 text-sm">
                                        <Link
                                          to="/forgot-password"
                                          className="text-primaryLime-500"
                                        >
                                          Forgot Password?
                                        </Link>
                                      </p>
                                    </div>
                                    <div className="flex items-start my-5">
                                      <div className="flex items-center h-5">
                                        <Controller
                                          name="terms"
                                          control={control}
                                          render={({ field }) => (
                                            <Checkbox
                                              {...field}
                                              className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                              required
                                            />
                                          )}
                                        />
                                      </div>
                                      <label className="ms-2 text-sm">
                                        I agree to the{" "}
                                        <Link
                                          className="underline text-primaryLime-500"
                                          to="/terms"
                                        >
                                          Terms & Conditions
                                        </Link>
                                      </label>
                                    </div>
                                    <p>
                                      <AppMessage
                                        status={appStatus}
                                        message={appMessage}
                                        vertical="top"
                                        horizontal="center"
                                        isOpen={appOpen}
                                        timeOut={100}
                                      ></AppMessage>
                                    </p>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                      aria-label="Sign in"
                                      type="submit"
                                      size="large"
                                      disabled={
                                        _.isEmpty(dirtyFields) || !isValid || loading
                                      }
                                    >
                                      {(loading && (
                                        <PulseLoader
                                          color="#000000"
                                          loading={loading}
                                          size={10}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      )) ||
                                        "Sign in"}
                                    </Button>
                                  </form>
                                </div>
                              </div>
                            ))))}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignIn;
