import http from "../../config/http-common";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

class UserService {
  updateUser = (user) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/Update/" + user.firstName + "/" + user.lastName)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateEmailAddress = (emailAddress, emailAddressID) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateEmailAddress/" + emailAddress + "/" + emailAddressID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyEmailOtp = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyEmailAddressVerificationCode/" + emailAddress + "/" + otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdatePhoneNumber = (phoneNumber, phoneNumberID) => {
    return new Promise((resolve, reject) => {
      http
        .post("User/AddUpdatePhoneNumber/" + phoneNumber + "/" + phoneNumberID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyPhoneOtp = (phone, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get("User/VerifyPhoneNumberVerificationCode/" + phone + "/" + otp)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updatePassword = (password) => {
    return new Promise((resolve, reject) => {
      http.put("User/UpdatePassword/" + password).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  resetPassword = (password, newPassword) => {
    return new Promise((resolve, reject) => {
      http.put("User/ResetPassword/" + password +"/" +newPassword).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  updateRecoveryEmail = (emailAddress) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateRecoveryEmailAddress/" + emailAddress)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateRecoveryPhone = (phone) => {
    return new Promise((resolve, reject) => {
      http.put("User/UpdateRecoveryPhoneNumber/" + phone).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

 verifyRecoveryPhone = (phone, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryPhoneNumberVerificationCode/" + phone + "/" + otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryEmail = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryEmailAddressVerificationCode/" + emailAddress + "/" + otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendEmailOTP = (emailAddress) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/ReSendEmailOTP/" + emailAddress)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendPhoneOTP = (phone) => {
    return new Promise((resolve, reject) => {
      http.put("User/ReSendPhoneOTP/" + phone).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

}

const instance = new UserService();

export default instance;
